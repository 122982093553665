import {
  asOneBasedPercentage,
  groupThenSumByColumn,
  isZero,
  numericValueIsDefined,
  ONE,
  ONE_HUNDRED,
  PossiblyNegativeNumber,
  sumListAsIntegersForManyFields,
  sumListAsIntegersWeightedByKey,
  sumListOfPossiblyUndefinedOrStringIntegers,
  validNumber,
  ZERO,
} from '../utils/numbers'
import Big from 'big.js'
import { NewClient } from './new-clients'
import {
  FilterType,
  FilterValues,
  isNameListFilter,
  isNegatedNumericFilter,
  isNegatedStringFilter,
  isNumericFilter,
} from './calculate-possible-filter-values'
import {
  isNotActuals,
  isNotReplacedByActuals,
  OVERRIDDEN_BY_ACTUALS,
  RENEWAL_INCEPTION_MONTH_COLUMN_NAME,
} from './calculate-with-actuals'
import { WayfinderDataType, WF_TYPE_OF_DATA_COLUMN } from './TypeOfData'
import { INCEPTION_MONTH_COLUMN_NAME } from '../components/renewal-graph/gwp-bar-chart/inception-month-graph'
import { Scenario } from './scenarios'
import { RawEntry } from './rawEntry'
import { MarketGraphDataV2, MarketSegmentV2, MarketV2 } from './market-V2'
import { ACTUALS_ADJUSTMENT_ID } from './calculate-with-actuals'
import { AggregatedMarketV2 } from './aggregated-market-V2'

export const TOTAL_GWP_COLUMN = 'Total GWP'
export const TOTAL_GWP_LEFT_TO_ADJUST_COLUMN = 'Total GWP left to adjust'
export const TOTAL_ADJUSTED_GWP_COLUMN = 'Total Adjusted GWP'
export const TOTAL_ADJUSTED_CONVEX_SHARE_CHANGE_COLUMN = 'Total Adjusted Convex Share Change As Integer Percentage'
export const TOTAL_ADJUSTED_RATE_CHANGE_COLUMN = 'Total Adjusted Rate Change As Integer Percentage'
export const TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN = 'Total Original GWP per Adjustment'
export const TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN = 'Total Adjusted GWP per Adjustment'
export const ADJUSTED_RISK_RATE_ADJUSTMENT_AMOUNT = 'WF Adjustment 100% Risk Premium Change'
export const ADJUSTED_CONVEX_SHARE_CHANGE_AMOUNT = 'WF Adjustment Convex Share Change'
export const ADJUSTED_GG_PREMIUM_CHANGE = 'WF Adjustment 100% GWP Change'
export const ADJUSTED_RENEWAL_RETENTION = 'WF Adjusted Renewal Retention'
export const CLIENT_NAME_COLUMN_IDENTIFIER = 'Client'
export const OLD_CLIENT_NAME_COLUMN_IDENTIFIER = 'Cedant Name'
export const REQUIREMENT_NAME_COLUMN_IDENTIFIER = 'Convex Requirement Name'
export const IS_PROJECTED_DATA_COLUMN = 'WF Is Projected Data'
export const CLIENT_RANKING_CATEGORY = 'Client Ranking Category'
export const CLIENT_RANKING_SCORE = 'Client Rank'
export const TOTAL_MARKET_GWP = 'Total Market GWP'
export const PML_SCENARIO_1_IN_100 = 'PML Scenario 1 in 100'
export const CONFIDENCE_IN_MARKET_SIZE = 'Confidence in Market size'
export const ACCESS_OR_CAPABILITY = 'Access or Capability'
export const IN_APPETITE = 'In Appetite'
export const ADDRESSABLE_MARKET_GWP = 'Addressable Market GWP'
export const RATE_ENVIRONMENT = 'Rate Environment'
export const PEAK_CONVEX_GWP = 'Peak Convex GWP'
export const PEAK_CONVEX_MARKET_SHARE = 'Peak Convex Market Share'
export const CONVEX_ESTIMATED_SHARE_OF_PML = 'Convex Estimated Share of PML'
export const PREVIOUS_YEAR_GNLR = 'Previous Year GNLR'
export const PREVIOUS_YEAR_ACQUISITION_RATIO = 'Previous Year Acquisition Ratio'
export const ACTUARIAL_INFLATION = 'Actuarial Inflation'
export const MARKET_GRARC = 'Market GRARC'
export const PREVIOUS_YEAR_GGLR = 'Previous Year GGLR'
export const CONVEX_ACQUISITION_RATIO = 'Convex Acquisition Ratio'
export const ACTUARIAL_ABE_GGLR = 'Actuarial ABE GGLR'
export const UW_CONVEX_ACQUISITION_RATIO = 'Underwriter Convex Acquisition Ratio'
export const MARKET_NRARC = 'Market NRARC'
export const UW_MARKET_GRARC = 'Underwriter Market GRARC'
export const UW_INFLATION = 'Underwriter Inflation'
export const UW_ABE_GGLR = 'Underwriter ABE GGLR'
export const GRAPH_DATA = 'Graph Data'
export const SEGMENTS = 'Segments'

export interface ScenarioKeyPerformanceIndicators {
  [TOTAL_GWP_COLUMN]: Big
  [TOTAL_GWP_LEFT_TO_ADJUST_COLUMN]: Big
  [TOTAL_ADJUSTED_GWP_COLUMN]: Big
  [TOTAL_ADJUSTED_CONVEX_SHARE_CHANGE_COLUMN]: Big
  [TOTAL_ADJUSTED_RATE_CHANGE_COLUMN]: Big
  [TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN]: {
    [adjustmentId: string]: Big | undefined
  }
  [TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN]: {
    [adjustmentId: string]: Big | undefined
  }
}

export type MarketKeyPerformanceIndicators = {
  [TOTAL_MARKET_GWP]: number
  [PML_SCENARIO_1_IN_100]: number
  [CONFIDENCE_IN_MARKET_SIZE]: number
  [ACCESS_OR_CAPABILITY]: number
  [IN_APPETITE]: number
  [ADDRESSABLE_MARKET_GWP]: number
  [RATE_ENVIRONMENT]: string
  [PEAK_CONVEX_GWP]: number
  [PEAK_CONVEX_MARKET_SHARE]: number
  [CONVEX_ESTIMATED_SHARE_OF_PML]: number
  [PREVIOUS_YEAR_GNLR]: number
  [PREVIOUS_YEAR_ACQUISITION_RATIO]: number
  [ACTUARIAL_INFLATION]: number
  [MARKET_GRARC]: number
  [PREVIOUS_YEAR_GGLR]: number
  [CONVEX_ACQUISITION_RATIO]: number
  [ACTUARIAL_ABE_GGLR]: number
  [UW_CONVEX_ACQUISITION_RATIO]: number
  [MARKET_NRARC]: number
  [UW_MARKET_GRARC]: number
  [UW_INFLATION]: number
  [UW_ABE_GGLR]: number
  [GRAPH_DATA]: MarketGraphDataV2[]
  [SEGMENTS]: MarketSegmentV2[]
}
export interface WeightedAvgKpis {
  riskPremiumChangeKpi: Big | undefined
  convexShareChangeKpi: Big | undefined
  lossRatioKpi: Big | undefined
  acqRatioKpi: Big | undefined
  ggPremiumChangeKpi: Big | undefined
  renewalRetentionKpi: Big | undefined
}

export const PREMIUM_COLUMN_NAME = 'Convex GWP'
export const CONVEX_LIMIT_COLUMN_NAME = 'Convex Limit'
export const WAYFINDER_UNIQUE_ID = 'WF Unique ID'
export const ADJUSTED_GROSS_RATE_NEW_PREMIUM_AMOUNT = 'WF Gross Rate New Premium Amount'
export const ADJUSTED_CONVEX_SHARE_NEW_PREMIUM_AMOUNT = 'WF Convex Share New Premium Amount'
export const ADJUSTED_PREMIUM_VALUE = 'WF Adjusted Convex GWP'
export const ADJUSTED_ONE_HUNDRED_PERCENT_PREMIUM_VALUE = 'WF 100% Adjusted Premium Value'
export const ADJUSTED_CONVEX_SHARE_VALUE = `WF Adjusted Convex Share`
export const ADJUSTED_CONVEX_LIMIT = `WF Adjusted Convex Limit`
export const ADJUSTED_CONVEX_LIMIT_WITH_BASIC_RETENTION = 'WF Adjusted Convex Limit with Basic Retention'
export const ADJUSTMENT_ID = 'WF Adjustment ID'
export const ADJUSTMENT_NAME = 'WF Adjustment Name'
export const ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION = 'WF Adjusted Convex GWP with Basic Retention'
export const ADJUSTED_PREMIUM_VALUE_WITH_SAMPLED_RETENTION = 'WF Adjusted Convex GWP with Sampled Retention'
export const ADJUSTED_CONVEX_GROSS_RISK_ADJUSTED_RATE_CHANGE_AMOUNT = 'WF Gross Risk Adjusted Rate Change'

const asPercentage = (number: number) => ONE.plus(new Big(number).div(ONE_HUNDRED))

export function calculateNewClientFields(scenario: Scenario, dataItems: NewClient[]) {
  return dataItems
    .filter((item) => item.isEnabled)
    .map((item) => ({
      'UW Division': scenario.division,
      'UW Team': scenario.team,
      'A1 Market': scenario.market,
      [WF_TYPE_OF_DATA_COLUMN]: WayfinderDataType.NEW_CLIENT,
      [RENEWAL_INCEPTION_MONTH_COLUMN_NAME]: item.inceptionMonth,
      [INCEPTION_MONTH_COLUMN_NAME]: item.inceptionMonth,
      [ADJUSTED_PREMIUM_VALUE]: item.convexGwp,
      [ADJUSTED_CONVEX_SHARE_VALUE]: asPercentage(item.convexShare as number).minus(ONE),
      [ADJUSTED_CONVEX_LIMIT]: item.convexLimit,
      'A1 Segment': item.segment,
      'COB - Tier 2': item.classOfBusiness,
      'Placing Basis Structure': item.placingBasis ?? '',
      'Placement Type': item.placementType,
      Currency: item.currency,
      [ADJUSTMENT_ID]: item.id,
      [ADJUSTMENT_NAME]: item.name,
      [CLIENT_NAME_COLUMN_IDENTIFIER]: item.name,
    }))
}

export function filterData(rawData: RawEntry[], filterToApply: FilterValues): RawEntry[] {
  const allColumnsToFilterBy = Object.entries(filterToApply)

  return allColumnsToFilterBy.reduce(filterRowByEachColumn, rawData)
}

export function filterRowByEachColumn(
  dataSoFar: RawEntry[],
  [columnNameToFilterOn, valuesToFilterOn]: [string, FilterType],
): RawEntry[] {
  if (isNameListFilter(valuesToFilterOn)) {
    const doesFilterListContainValue = (dataItem: RawEntry) => {
      return valuesToFilterOn.includes(dataItem[columnNameToFilterOn])
    }
    return dataSoFar.filter(doesFilterListContainValue)
  } else if (isNegatedStringFilter(valuesToFilterOn)) {
    const negatedFilter = valuesToFilterOn
    const filterListDoesNotContainValue = (dataItem: RawEntry) => {
      return !negatedFilter.valuesToBeIgnored.includes(dataItem[columnNameToFilterOn])
    }
    return dataSoFar.filter(filterListDoesNotContainValue)
  } else if (isNumericFilter(valuesToFilterOn)) {
    const isWithinRange = (dataItem: any) => {
      const value = dataItem[columnNameToFilterOn]
      return value <= valuesToFilterOn.maximumValueInclusive && value >= valuesToFilterOn.minimumValueInclusive
    }
    return dataSoFar.filter(isWithinRange)
  } else if (isNegatedNumericFilter(valuesToFilterOn)) {
    const isOutsideRange = (dataItem: any) => {
      const value = dataItem[columnNameToFilterOn]
      return (
        value > valuesToFilterOn.maximumValueOfRangeToIgnoreInclusive ||
        value < valuesToFilterOn.minimumValueOfRangeToIgnoreInclusive
      )
    }
    return dataSoFar.filter(isOutsideRange)
  } else {
    return [...dataSoFar]
  }
}

export function calculateKPIsAsNumbers(adjustedData: any[]) {
  const kpis = calculateKPIs(adjustedData)

  return Object.entries(kpis)
    .map(([key, value]) => {
      if (value.toNumber !== undefined) {
        return [key, value.toNumber()]
      } else {
        return [key, value]
      }
    })
    .reduce((fullData, [key, value]) => ({ ...fullData, [key]: value }), {})
}

export function calculateKPIs(adjustedData: any[]): ScenarioKeyPerformanceIndicators {
  const totalsForANumberOfFields: Record<string, undefined | Big> = sumListAsIntegersForManyFields(adjustedData, [
    {
      fieldName: PREMIUM_COLUMN_NAME,
      resultName: 'total',
      filterLogic: (item) => !item[IS_PROJECTED_DATA_COLUMN],
    },
    {
      fieldName: PREMIUM_COLUMN_NAME,
      resultName: 'totalWithoutActuals',
      filterLogic: (item) => !item[IS_PROJECTED_DATA_COLUMN] && isNotReplacedByActuals(item) && isNotActuals(item),
    },
    {
      fieldName: ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION,
      fallbackFieldNames: [ADJUSTED_PREMIUM_VALUE, PREMIUM_COLUMN_NAME],
      resultName: 'totalNewPremiumAmount',
      filterLogic: (item) =>
        isNotReplacedByActuals(item) && item[WF_TYPE_OF_DATA_COLUMN] !== WayfinderDataType.RENEWAL_UNADJUSTED,
    },
    {
      fieldName: ADJUSTED_GROSS_RATE_NEW_PREMIUM_AMOUNT,
      fallbackFieldNames: [PREMIUM_COLUMN_NAME],
      resultName: 'totalGrossRatePremiumAmount',
      filterLogic: (item) => isNotReplacedByActuals(item) && isNotActuals(item),
    },
    {
      fieldName: ADJUSTED_CONVEX_SHARE_NEW_PREMIUM_AMOUNT,
      fallbackFieldNames: [PREMIUM_COLUMN_NAME],
      resultName: 'totalConvexSharePremiumAmount',
      filterLogic: (item) => isNotReplacedByActuals(item) && isNotActuals(item),
    },
    {
      fieldName: PREMIUM_COLUMN_NAME,
      resultName: 'totalLeftToAdjust',
      filterLogic: (item) => {
        return item[ADJUSTMENT_ID] === undefined
      },
    },
  ])

  const totalAdjustedGwpPerAdjustment = groupThenSumByColumn(
    adjustedData.filter(isNotReplacedByActuals),
    ADJUSTMENT_ID,
    ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION,
    ADJUSTED_PREMIUM_VALUE,
  )
  const totalOriginalGwpPerAdjustment = groupThenSumByColumn(adjustedData, ADJUSTMENT_ID, PREMIUM_COLUMN_NAME)

  return {
    [TOTAL_GWP_COLUMN]: totalsForANumberOfFields['total']!,
    [TOTAL_GWP_LEFT_TO_ADJUST_COLUMN]: totalsForANumberOfFields['totalLeftToAdjust']!,
    [TOTAL_ADJUSTED_GWP_COLUMN]: totalsForANumberOfFields['totalNewPremiumAmount']!, //
    [TOTAL_ADJUSTED_RATE_CHANGE_COLUMN]: isZero(totalsForANumberOfFields['totalWithoutActuals'])
      ? ZERO
      : totalsForANumberOfFields['totalGrossRatePremiumAmount']!.div(totalsForANumberOfFields['totalWithoutActuals']!)
          .minus(ONE)
          .mul(ONE_HUNDRED),
    [TOTAL_ADJUSTED_CONVEX_SHARE_CHANGE_COLUMN]: isZero(totalsForANumberOfFields['totalWithoutActuals'])
      ? ZERO
      : totalsForANumberOfFields['totalConvexSharePremiumAmount']!.div(totalsForANumberOfFields['totalWithoutActuals']!)
          .minus(ONE)
          .mul(ONE_HUNDRED),
    [TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN]: totalAdjustedGwpPerAdjustment,
    [TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN]: totalOriginalGwpPerAdjustment,
  }
}

export function calculateKpiOfFieldWeightedByGwpButFallBackToIfPremiumIsZero(
  data: Record<string, string | number | Big | undefined>[],
  keyToSum: string,
  fallbackValuePerMarket: Record<string, string | number | Big | undefined> = {},
  fallbackKeyToSum?: string | undefined,
  premiumWeightingColumns = [PREMIUM_COLUMN_NAME, ADJUSTED_PREMIUM_VALUE],
): Big | undefined {
  if (data.length === 0) {
    return undefined
  }

  let totalOfFieldWeightedByGwp = sumListAsIntegersWeightedByKey(
    data,
    keyToSum,
    fallbackKeyToSum,
    premiumWeightingColumns,
    0,
    fallbackValuePerMarket,
  )
  return totalOfFieldWeightedByGwp ?? undefined
}

export function calculateWeightedAverageKpis(
  rawSetOfData: Record<string, string | number | Big | undefined>[],
  fallbackAcqRatioValuePerMarket: Record<string, string | number | Big | undefined>,
): WeightedAvgKpis {
  const dataIgnoringOverridden = rawSetOfData.filter((item) => item?.[ADJUSTMENT_ID] !== OVERRIDDEN_BY_ACTUALS)
  const dataIgnoringOverriddenAndActuals = rawSetOfData.filter(
    (item) => item?.[ADJUSTMENT_ID] !== OVERRIDDEN_BY_ACTUALS && item?.[ADJUSTMENT_ID] !== ACTUALS_ADJUSTMENT_ID,
  )

  const riskPremiumChangeKpi = calculateKpiOfFieldWeightedByGwpButFallBackToIfPremiumIsZero(
    dataIgnoringOverridden,
    ADJUSTED_RISK_RATE_ADJUSTMENT_AMOUNT,
  )

  const convexShareChangeKpi = calculateKpiOfFieldWeightedByGwpButFallBackToIfPremiumIsZero(
    dataIgnoringOverriddenAndActuals,
    ADJUSTED_CONVEX_SHARE_CHANGE_AMOUNT,
  )

  const ggPremiumChangeKpi = calculateKpiOfFieldWeightedByGwpButFallBackToIfPremiumIsZero(
    dataIgnoringOverridden,
    ADJUSTED_GG_PREMIUM_CHANGE,
  )

  const renewalRetentionKpi = calculateKpiOfFieldWeightedByGwpButFallBackToIfPremiumIsZero(
    dataIgnoringOverriddenAndActuals,
    ADJUSTED_RENEWAL_RETENTION,
  )

  const lossRatioKpi = calculateKpiOfFieldWeightedByGwpButFallBackToIfPremiumIsZero(
    dataIgnoringOverridden,
    'WF EULR',
    {},
    'EULR',
    [ADJUSTED_PREMIUM_VALUE, PREMIUM_COLUMN_NAME],
  )?.mul(ONE_HUNDRED)

  const acqRatioKpi = calculateKpiOfFieldWeightedByGwpButFallBackToIfPremiumIsZero(
    dataIgnoringOverridden,
    'WF Acq Cost',
    {},
    'Acq Costs',
    [ADJUSTED_PREMIUM_VALUE, PREMIUM_COLUMN_NAME],
  )?.mul(ONE_HUNDRED)

  return {
    acqRatioKpi,
    lossRatioKpi,
    riskPremiumChangeKpi,
    convexShareChangeKpi,
    ggPremiumChangeKpi,
    renewalRetentionKpi,
  }
}

export function calculateConvexGwp(
  fullRenewalKpis: ScenarioKeyPerformanceIndicators | undefined,
  fullNewKpis: ScenarioKeyPerformanceIndicators | undefined,
): Big {
  const calculatedConvexGwp =
    fullRenewalKpis && fullNewKpis
      ? fullNewKpis[TOTAL_ADJUSTED_GWP_COLUMN].plus(fullRenewalKpis[TOTAL_ADJUSTED_GWP_COLUMN])
      : ZERO
  return calculatedConvexGwp
}

export function calculateConvexMarketShare(convexGwp: Big, totalMarketGwp: number): number {
  return (Number(convexGwp) / totalMarketGwp) * 100
}

export function calculateCoreClientsPercentage(fullDataForScenario: RawEntry[]): number {
  let result = sumListAsIntegersForManyFields(fullDataForScenario, [
    {
      fieldName: ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION,
      fallbackFieldNames: [ADJUSTED_PREMIUM_VALUE, PREMIUM_COLUMN_NAME],
      resultName: 'total',
      filterLogic: (item) => isNotReplacedByActuals(item),
    },
    {
      fieldName: ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION,
      fallbackFieldNames: [ADJUSTED_PREMIUM_VALUE, PREMIUM_COLUMN_NAME],
      resultName: 'totalCoreClients',
      filterLogic: (item) =>
        isNotReplacedByActuals(item) &&
        (item[CLIENT_RANKING_CATEGORY] === 'Target' || item[CLIENT_RANKING_CATEGORY] === 'Neutral'),
    },
  ])

  const { total, totalCoreClients } = result

  if (!total || !totalCoreClients || total === ZERO || totalCoreClients === ZERO) {
    return ZERO.toNumber()
  }

  return totalCoreClients.div(total).mul(100).toNumber()
}

export function calculateGrarcKpi(data: Record<string, string | number | Big | undefined>[]): Big | undefined {
  const NEGATIVE_100 = -100

  let totalGwp: Big = ZERO
  let totalGwpExcludingGrarc: Big = ZERO

  data.forEach((currentDataItem) => {
    const rawGwp = currentDataItem[ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION]
      ? currentDataItem[ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION]
      : currentDataItem[ADJUSTED_PREMIUM_VALUE]

    const rawGrarc = currentDataItem[ADJUSTED_CONVEX_GROSS_RISK_ADJUSTED_RATE_CHANGE_AMOUNT]

    if (numericValueIsDefined(rawGrarc) && rawGrarc !== NEGATIVE_100) {
      // using conditional operator to avoid creating a new Big objection from an empty string,
      // which results in an error.
      const gwp = rawGwp ? new Big(rawGwp) : ZERO
      const grarc = rawGrarc ? new Big(rawGrarc) : ZERO
      const oneBasedGrarcPercentage = asOneBasedPercentage(grarc)
      const gwpExcludingGrarc = gwp.div(oneBasedGrarcPercentage)

      totalGwp = totalGwp.plus(gwp)
      totalGwpExcludingGrarc = totalGwpExcludingGrarc.plus(gwpExcludingGrarc)
    }
  })

  if (isZero(totalGwpExcludingGrarc)) {
    return undefined
  }

  return new Big(totalGwp.div(totalGwpExcludingGrarc).minus(ONE).mul(ONE_HUNDRED).toFixed(2))
}

// We should use the calculated GRARC KPI if available, else extract from marketData, else extract from defaultMarketData, else return 0
export function calculateMarketGrarc(
  grarcKpiValue: Big | undefined,
  market: MarketV2 | undefined,
  defaultMarket: MarketV2 | undefined,
): number {
  let grarc
  if (grarcKpiValue) {
    grarc = grarcKpiValue
  } else if (market) {
    grarc = market.marketGrarc
  } else if (defaultMarket) {
    grarc = defaultMarket.marketGrarc
  } else {
    grarc = 0
  }
  return Number(grarc)
}

export function calculateMarketNRARC(
  grossRateChange: string | PossiblyNegativeNumber,
  currentAcqRatio: string | PossiblyNegativeNumber,
  previousAcqRatio: string | PossiblyNegativeNumber,
): Big {
  const grossRate = new Big(grossRateChange ?? 0).div(ONE_HUNDRED)
  const currentAcq = new Big(currentAcqRatio ?? 0).div(ONE_HUNDRED)
  const previousAcq = new Big(previousAcqRatio ?? 0).div(ONE_HUNDRED)

  const onePlusGRARC = ONE.plus(grossRate)
  const oneMinusLastYear = ONE.minus(previousAcq)
  const oneMinusThisYear = ONE.minus(currentAcq)

  return onePlusGRARC.mul(oneMinusThisYear).div(oneMinusLastYear).minus(1).mul(ONE_HUNDRED)
}

export function calculateGGLR(
  inputMarketLr: string | PossiblyNegativeNumber,
  inputAcqCost: string | PossiblyNegativeNumber,
  inputInflation: string | PossiblyNegativeNumber,
  inputRarc: string | PossiblyNegativeNumber,
): Big {
  const marketLr = new Big(inputMarketLr ?? 0).div(ONE_HUNDRED)
  const acqCost = new Big(inputAcqCost ?? 0).div(ONE_HUNDRED)
  const inflation = new Big(inputInflation ?? 0).div(ONE_HUNDRED)
  const rarc = new Big(inputRarc ?? 0).div(ONE_HUNDRED)

  const oneMinusAcqCost = ONE.minus(acqCost)
  const onePlusInflation = ONE.plus(inflation)
  const onePlusRarc = ONE.plus(rarc)

  if (onePlusInflation.cmp(ZERO) <= 0) {
    return ZERO
  }

  const gglr = marketLr.mul(oneMinusAcqCost)
  const profitChange = onePlusRarc.div(onePlusInflation)
  const value = gglr.div(profitChange)

  return value.mul(ONE_HUNDRED)
}

// If the year is a future year then the market requires adjustment
export function requiresAdjustment(year: string): boolean {
  return Number(year) - new Date().getFullYear() >= 1
}

export function calculateProfitabilityChange(grarc: Big | undefined, inflation: number): Big {
  const calculatedProfitabilityChange = asOneBasedPercentage(grarc ?? ZERO)
    .div(asOneBasedPercentage(new Big(inflation)))
    .minus(ONE)
  return calculatedProfitabilityChange.times(ONE_HUNDRED)
}

export function calculateConvexGwpForSegment(fullDataForSegment: RawEntry[]): number {
  let result = sumListAsIntegersForManyFields(fullDataForSegment, [
    {
      fieldName: ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION,
      fallbackFieldNames: [ADJUSTED_PREMIUM_VALUE, PREMIUM_COLUMN_NAME],
      resultName: 'total',
      filterLogic: (item) => isNotReplacedByActuals(item),
    },
  ])

  const total = result['total']

  if (!total || total === ZERO) {
    return ZERO.toNumber()
  }

  return total.toNumber()
}

export function calculateAddressableMarketGwp(
  total: PossiblyNegativeNumber,
  accesscapability: PossiblyNegativeNumber,
  inappetite: PossiblyNegativeNumber,
): number {
  const safeTotal = validNumber(total) ? Number(total) : 0
  const safeAccesscapability = validNumber(accesscapability) ? Number(accesscapability) : 0
  const safeInappetite = validNumber(inappetite) ? Number(inappetite) : 0

  return safeTotal * (safeAccesscapability / 100) * (safeInappetite / 100)
}

export function sumTotalMarketGwp(subScenariosData: undefined | AggregatedMarketV2[]): number | undefined {
  if (!subScenariosData) {
    return undefined
  }
  const totals: number[] = subScenariosData.map((item) => item.totalMarketGwp)
  return sumListOfPossiblyUndefinedOrStringIntegers(totals)
}
